import React from 'react'
import { Grid, P, Divider, H, Image, Wrapper } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'
import { graphql, useStaticQuery } from 'gatsby'

import DefaultLayout from 'layouts/DefaultLayout'
import HeroWithImage from 'components/HeroWithImage'
import FrequentlyAskedQuestions from 'components/FrequentlyAskedQuestions'

import { WILLS } from 'lib/products/constants'
import {
  ONLINE_WILL_PRICE_IN_POUNDS,
  TELEPHONE_WILL_PRICE_IN_POUNDS,
  ONLINE_WILL_SUBSCRIPTION_PRICE_IN_POUNDS,
} from 'config'
import { formatPriceInPounds } from 'lib/formatting/pricing'

import Details from './Details'
import Testimonial from './Testimonial'

const WillsPricingPage = () => {
  const { faqSection } = useStaticQuery(graphql`
    query {
      faqSection: contentfulFaqSection(entryTitle: { eq: "Wills Pricing" }) {
        ...FaqSectionFields
      }
    }
  `)
  return (
    <DefaultLayout
      title="How much does it cost to make a will? | Will writing costs"
      description={`It costs ${formatPriceInPounds(
        ONLINE_WILL_PRICE_IN_POUNDS
      )} to make a will online, or ${formatPriceInPounds(
        TELEPHONE_WILL_PRICE_IN_POUNDS
      )} if you would prefer to do it over the phone with a specialist. For face-to-face services on the high street, you could pay as much as £300.`}
      product={WILLS}
    >
      <HeroWithImage
        title="How much does it cost to make a will?"
        subtitle={`Our prices start at just ${formatPriceInPounds(
          ONLINE_WILL_PRICE_IN_POUNDS
        )} – saving you up to £150 compared to solicitors on the high street.`}
        image={
          <Image
            path="illustrations/turtle-with-money"
            width={300}
            widthFromM={400}
            style={{ margin: '-30px -20px' }}
            stretch
          />
        }
      />
      <Details />
      <Testimonial />
      <Grid container style={{ alignItems: 'center' }}>
        <Grid.Item spanFromM={7}>
          <H size="L" decorative>
            Update your will anytime
          </H>
          <P>
            When life changes, so should your will. So our unlimited update
            subscription makes it simple. For just{' '}
            {formatPriceInPounds(ONLINE_WILL_SUBSCRIPTION_PRICE_IN_POUNDS)} a
            year you can make as many updates as you need, at no extra cost. Our
            specialists will check your changes and make sure everything is as
            clear as it needs to be. So that&apos;s one less thing to worry
            about.
          </P>
        </Grid.Item>
        <Grid.Item
          margin={['M', 0]}
          spanFromM={4}
          startColumnFromM={9}
          centered
        >
          <Image
            path="illustrations/cropped/checks-passed"
            width={250}
            stretch
          />
        </Grid.Item>
      </Grid>
      <Divider />
      <Wrapper background={COLOR.WHITE}>
        <Wrapper container>
          <FrequentlyAskedQuestions
            title={faqSection.title}
            questions={faqSection.questions}
            relatedGuide={faqSection.relatedGuide}
            selectedProduct={WILLS}
            image="illustrations/coins-turtle-revised"
          />
        </Wrapper>
      </Wrapper>
    </DefaultLayout>
  )
}

export default WillsPricingPage
