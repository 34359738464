import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Grid, Image, Wrapper, P, H, Divider } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import { formatPriceInPounds } from 'lib/formatting/pricing'
import LinkButton from 'components/LinkButton'
import BulletedList from 'components/BulletedList'

const StyledGrid = styled(Grid)`
  align-items: center;
`
const StyledImageWrapper = styled(Wrapper)`
  height: 150px;
  display: flex;
  align-items: center;
`

const StyledImage = styled(Image)`
  width: 100%;
  ${({ scale }) => `transform: scale(${scale})`};
`

const StyledLink = styled(Link)`
  color: ${COLOR.ACCENT.SECONDARY};
`

const Card = ({
  title,
  singlePrice,
  couplesPrice,
  image,
  scale,
  buttonText,
  buttonLink,
  productLink,
  bulletedItems,
  linkText,
  linkTargetUrl,
}) => (
  <Wrapper bordered borderRadius="M" padding="M" background={COLOR.WHITE}>
    <H margin={0} size="M">
      {title}
    </H>
    <StyledGrid marginFromM={['M', 0]}>
      <Grid.Item span={6}>
        <H decorative size="XL" tag="h1" style={{ display: 'inline' }}>
          {formatPriceInPounds(singlePrice)}
        </H>
        <P color={COLOR.BLACK}>
          {formatPriceInPounds(couplesPrice)} for couples
        </P>
      </Grid.Item>
      <Grid.Item span={6} startColumnFromL={8}>
        <StyledImageWrapper>
          <StyledImage path={image} width={220} scale={scale} />
        </StyledImageWrapper>
      </Grid.Item>
    </StyledGrid>
    <LinkButton productLink={productLink} to={buttonLink} wide={false} stretch>
      {buttonText}
    </LinkButton>
    <Divider margin={['S', 0]} />
    <BulletedList.Check bulletColor={COLOR.GREY.DARK} items={bulletedItems} />
    <Divider margin={['S', 0]} />
    <StyledLink to={linkTargetUrl}>{linkText}</StyledLink>
  </Wrapper>
)

Card.propTypes = {
  title: PropTypes.string.isRequired,
  singlePrice: PropTypes.number.isRequired,
  couplesPrice: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
  scale: PropTypes.number,
  buttonText: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
  productLink: PropTypes.bool,
  bulletedItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  linkText: PropTypes.string.isRequired,
  linkTargetUrl: PropTypes.string.isRequired,
}

Card.defaultProps = {
  productLink: false,
  scale: 1,
}

export default Card
