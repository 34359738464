import React from 'react'
import { Wrapper } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import WrapperWithEdge from 'components/WrapperWithEdge'
import CustomerQuote, { CUSTOMER_QUOTE_ID } from 'components/CustomerQuote'

const Testimonial = () => (
  <WrapperWithEdge edgeBackgroundColor={COLOR.BACKGROUND.FOG} direction="down">
    <Wrapper containerPaddingTop="XL" containerPaddingBottom="XL" container>
      <CustomerQuote id={CUSTOMER_QUOTE_ID.JASON} />
    </Wrapper>
  </WrapperWithEdge>
)

export default Testimonial
