import React from 'react'
import { Wrapper } from '@farewill/ui'

import {
  ONLINE_WILL_PRICE_IN_POUNDS,
  ONLINE_WILL_COUPLES_PRICE_IN_POUNDS,
  TELEPHONE_WILL_PRICE_IN_POUNDS,
  TELEPHONE_WILL_COUPLES_PRICE_IN_POUNDS,
  TELEPHONE_WILL_ADDITIONAL_COMPLEXITY_PRICE_IN_POUNDS,
  WILLS_SIGN_UP_PATH,
  CALLBACK_BOOKING_FEATURE_ENABLED,
  TELEPHONE_WILLS_APPOINTMENT_BOOKING_URL,
  FF_TELEPHONE_WILL_ADDITIONAL_COMPLEXITY_ENABLED,
} from 'config'
import PATHS from 'paths'
import PricingCards from 'components/PricingCards'

import Card from './Card'

const Details = () => (
  <Wrapper container containerPaddingTop={0} containerPaddingBottom="XL">
    <PricingCards>
      <Card
        title="Online wills"
        singlePrice={ONLINE_WILL_PRICE_IN_POUNDS}
        couplesPrice={ONLINE_WILL_COUPLES_PRICE_IN_POUNDS}
        image="illustrations/cropped/blob-with-laptop-and-cat"
        buttonText="Get started for free"
        buttonLink={WILLS_SIGN_UP_PATH}
        productLink
        bulletedItems={[
          'Get free support by email or on the phone',
          'Only pay when your will is ready to be checked',
          'Ready to download in under 5 days',
        ]}
        linkText="How online wills work"
        linkTargetUrl="/make-a-will-online"
      />
      <Card
        title="Telephone wills"
        singlePrice={TELEPHONE_WILL_PRICE_IN_POUNDS}
        couplesPrice={TELEPHONE_WILL_COUPLES_PRICE_IN_POUNDS}
        image="illustrations/cropped/phone-with-hand"
        scale={0.6}
        buttonText={
          CALLBACK_BOOKING_FEATURE_ENABLED
            ? 'Book my free callback'
            : 'Book a free callback'
        }
        buttonLink={
          CALLBACK_BOOKING_FEATURE_ENABLED
            ? TELEPHONE_WILLS_APPOINTMENT_BOOKING_URL
            : PATHS.WILLS.TELEPHONE_CONTACT
        }
        bulletedItems={[
          'Get your will written by a specialist over the phone',
          'Include more complex wishes in your will',
          FF_TELEPHONE_WILL_ADDITIONAL_COMPLEXITY_ENABLED
            ? `Add unlimited specialist clauses for £${TELEPHONE_WILL_ADDITIONAL_COMPLEXITY_PRICE_IN_POUNDS} if you need to`
            : 'Receive it in the post in 10 working days',
        ]}
        linkText="How telephone wills work"
        linkTargetUrl="/wills/telephone-wills"
      />
    </PricingCards>
  </Wrapper>
)

export default Details
